(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Mute popover
     *   - Modal to allow users to mute/unmute other users.
     *
     * Usages:
     *   - userProfile.jsp
     *
     * - Dependencies
     *   - Mute-confirm-modal.js
     *
     * - Warning:
     *   - mute-popover requires the user to be logged in for the mute/unmute action.
     *
     */
    Vue.component("mute-popover", {
        props: [
            "userName",
            "userId",
            "isMuted",
            "isPopoverOpen",
            "i18n",
            "target", //element to calculate the absolute position for the popover
        ],
        data: function () {
            return {
                isConfirmModalOpen: false,
                isPopoverTriangleActive: false,
            };
        },
        template: `
            <div v-if="isPopoverOpen" ref="mutePopover" class="mute-popover__mask">

                <div id="mute-popover__content" class="mute-popover__content" ref="mutePopoverContent" style="visibility: hidden;"
                    @mouseover="isPopoverTriangleActive = true"
                    @mouseleave="mouseLeavePopover"
                >
                    <div class="mute-popover__content-wrapper">
                        <a v-if="isMuted" href="#" class="mute-popover__button" @click="onClickUnmute">
                            <div class="mute-popover__content-table">
                                <div class="mute-popover__content-table-row">
                                    <div class="mute-popover__content-image"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/user-profile/sound.svg"/></div>
                                    <div class="mute-popover__content-text" v-html="i18n.txtMutePopoverUnmute"></div>
                                </div>
                            </div>
                        </a>
                        <a v-else href="#" class="mute-popover__button" @click="openMuteConfirmModal">
                            <div class="mute-popover__content-table">
                                <div class="mute-popover__content-table-row">
                                    <div class="mute-popover__content-image"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/user-profile/no_sound.svg"/></div>
                                    <div class="mute-popover__content-text">{{i18n.txtMutePopoverMute}}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                
                <div v-if="isPopoverTriangleActive" id="mute-popover__triangle" class="mute-popover__triangle-active" ref="mutePopoverTriangle"></div>
                <div v-else id="mute-popover__triangle" ref="mutePopoverTriangle"></div>
                <div id="mute-popover__triangle-shadow" ref="mutePopoverTriangleShadow"></div>
                
                <!--  Modal confirm mute action -->
                <mute-confirm-modal
                    :isModalOpen="isConfirmModalOpen"
                    :i18n="i18n"
                    @closeMuteConfirmModal="closeMuteConfirmModal"
                    >
                </mute-confirm-modal>
                
            </div>
        `,
        methods: {
            onClickOutside: onClickOutside,
            muteUnmuteUser: muteUnmuteUser,

            onClickUnmute: function () {
                muteUnmuteUser(this);
                this.isPopoverTriangleActive = false;
            },

            mouseLeavePopover: function () {
                if (!this.isConfirmModalOpen) {
                    this.isPopoverTriangleActive = false;
                }
            },

            // Mute confirm modal methods
            openMuteConfirmModal: function () {
                this.isConfirmModalOpen = true;
                document
                    .getElementById("mute-popover__content")
                    .classList.add("mute-popover__active");
            },

            closeMuteConfirmModal: function (payload) {
                this.isConfirmModalOpen = false;
                document
                    .getElementById("mute-popover__content")
                    .classList.add("mute-popover__active");
                this.isPopoverTriangleActive = false;
                if (payload.accepted) {
                    muteUnmuteUser(this);
                } else {
                    this.$emit("closeMutePopoverEvent", {});
                }
            },
        },
        watch: {
            isPopoverOpen: isPopoverOpen,
            target: target,
        },
    });

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function isPopoverOpen(newIsPopoverOpen, oldIsPopoverOpen) {
        _doubleRaf(() => {
            if (newIsPopoverOpen) {
                _resizeEventListenerOn(this);
                _calculatePopoverPosition(this, this.target);
                _addOnClickOutsideEvent(this);
            } else {
                _resizeEventListenerOff(this);
            }
        });
    }

    function _resizeEventListenerOn(vueInstance) {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;

        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty("--vh", `${vh}px`);

        // We listen to the resize event
        window.addEventListener("resize", function () {
            _calculatePopoverPosition(vueInstance, vueInstance.target);
        });
    }

    function _resizeEventListenerOff(vueInstance) {
        window.removeEventListener("resize", function () {
            _calculatePopoverPosition(vueInstance, vueInstance.target);
        });
    }

    function _calculatePopoverPosition(vueInstance, target) {
        let mutePopoverContent = vueInstance.$refs.mutePopoverContent,
            mutePopoverTriangle = vueInstance.$refs.mutePopoverTriangle,
            mutePopoverTriangleShadow =
                vueInstance.$refs.mutePopoverTriangleShadow;

        if (target && target.offsetParent && mutePopoverContent) {
            if (target.tagName === "IMG") {
                target = target.parentElement;
            }

            let triggerRect = target.getBoundingClientRect(),
                triggerCenterH = triggerRect.right - triggerRect.width / 2,
                elementRect = mutePopoverContent.getBoundingClientRect(),
                triangleRect = mutePopoverTriangle.getBoundingClientRect();

            // Set absolute left position of popover
            if (triggerCenterH >= window.innerWidth / 2) {
                // Open popover from right to left
                mutePopoverContent.style.left =
                    triggerRect.right - elementRect.width + "px";
            } else {
                // Open popover from right to left
                mutePopoverContent.style.left = triggerRect.left + "px";
            }

            // Center horizontally the popover triangle and its shadow.
            mutePopoverTriangle.style.left =
                triggerCenterH - triangleRect.width / 2 + "px";
            mutePopoverTriangleShadow.style.left =
                triggerCenterH - triangleRect.width / 2 + "px";

            // Set absolute top position of popover content, triangle and triangle' shadow
            mutePopoverContent.style.top =
                triggerRect.bottom + window.scrollY + 1 + "px";
            mutePopoverTriangle.style.top =
                triggerRect.bottom + window.scrollY + 2 + "px";
            mutePopoverTriangleShadow.style.top =
                triggerRect.bottom + window.scrollY + 0.5 + "px";

            //check bounds viewport
            var width = window.screen.width;
            var newElement = mutePopoverContent.getBoundingClientRect();
            if (newElement.left < 0) {
                let moveRight = newElement.left * -1;
                mutePopoverContent.style.left =
                    newElement.left + moveRight + "px";
            } else if (width < newElement.right) {
                let moveLeft = newElement.right - width;
                mutePopoverContent.style.left =
                    newElement.left - moveLeft + "px";
            }
            mutePopoverContent.style.display = "table";
            mutePopoverContent.style.position = "absolute";
            mutePopoverContent.style.visibility = "visible";
        } else {
            //if target doesnt appear in the screen, we can hide the popover
            // useful when the mute layout changes to another icon
            vueInstance.$emit("closeMutePopoverEvent", {});
        }
    }

    function target(newTarget, oldTarget) {
        //We use the next tick to be able to use the $refs value
        this.$nextTick(() => {
            _calculatePopoverPosition(this, newTarget);
        });
    }

    function onClickOutside(event) {
        // stop click propagation for parent modals
        // without this action, the clickOutside event is triggered in the parent modal, closing it as well.
        event.stopPropagation();
        const el = this.$refs.mutePopoverContent;
        if (el && !el.contains(event.target)) {
            this.$emit("closeMutePopoverEvent", {});
        }
    }

    function muteUnmuteUser(vueInstance) {
        let url = "/wikiloc/mute.do";
        let params =
            "userId=" + vueInstance.userId + "&mute=" + !vueInstance.isMuted;

        _ajaxPost(url, params, (data) => {
            let response = _processMuteResponse(data, vueInstance);
            let isResponseOK = response && Object.keys(response).length > 0;

            if (isResponseOK) {
                vueInstance.$eventBus.$emit("mutePopoverIsMuted", {
                    isMuted: response.isMuted,
                });
            }

            vueInstance.$emit("closeMutePopoverEvent", {});
        });
    }

    function _processMuteResponse(result, vueInstance) {
        var muteResponse = {};

        if (result.res === "OK") {
            muteResponse = _factoryMuteResponse(result);
        }

        return muteResponse;
    }

    function _factoryMuteResponse(response) {
        return new MuteResponse(response.res, response.isMuted);
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.mutePopover.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    /**
     *    Post Async ajax call
     */
    function _ajaxPost(url, formData, callback) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                try {
                    var data = JSON.parse(xmlhttp.responseText);
                } catch (err) {
                    console.error(err.message + " in " + xmlhttp.responseText);
                    return;
                }
                callback(data);
            }
        };

        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded; charset=UTF-8"
        );
        xmlhttp.send(formData);
    }

    /**
     * --------------
     * ---- DTOs ----
     * --------------
     *
     */
    function MuteResponse(success, isMuted) {
        this.success = success;
        this.isMuted = isMuted;
    }
})();
